import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

export const Localization = (props) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAbge-ZojiAjaLmM6vlhti-xeVBBB1d9Ns"
  })

  const position = {
    lat: -21.767125,
    lng: -48.190580
  }

  // FUNÇÃO PARA ABRIR WHATS EM NOVA GUIA (ABA)
  function openWhats(){
    const url = 'https://wa.me/5516997497158'
    window.open(url, '_blank').focus();
  }

  return (
    <div id="localization" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Localização</h2>
        </div>
        <div id="row">
          {
            isLoaded ? (
              <GoogleMap
                mapContainerStyle={{width: '100%', height: '50%'}}
                center={position}
                zoom={16}
              >
                <Marker 
                  position={position} 
                />
              </GoogleMap>
          ) : <></>
          }
        </div>
        <br/><br/>
        <div id="row">
          <button className="btn btn-whats btn-lg" onClick={openWhats}>
            <i className="fa fa-whatsapp" style={{fontSize:' 18px'}}> Quero Aproveitar</i>
          </button>
        </div>
      </div>
    </div>
  );
};
