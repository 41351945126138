import { useState } from "react";
import emailjs from "emailjs-com";
import InputMask from "react-input-mask";
import React from "react";
import { toast } from 'react-toastify';


export const Header = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [celular, setCelular] = useState('')
  const [cpf, setCpf] = useState('')

  const service = process.env.REACT_APP_SERVICE_ID
  const template = process.env.REACT_APP_TEMPLATE_ID
  const key = process.env.REACT_APP_PUBLIC_KEY

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const templateParams = {
      from_name: name,
      message: message,
      email: email,
      celular: celular,
      cpf: cpf ? cpf : 'Não Informado'
    }

    emailjs
      .send(service, template, templateParams, key)
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Mensagem enviada!");
          setName('')
          setEmail('')
          setMessage('')
          setCelular('')
          setCpf('')
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            {/* <div className="row"> */}
              {/* <div className="col-md-8 col-md-offset-2 intro-text"> */}
                <div className="content">
                  <div className="contato">
                    <form name="sentMessage" validate onSubmit={handleSubmit}>
                      <p style={{textAlign:"center", fontWeight:"bold"}}>Entre em contato</p>
                      <div className="col-md-12">
                        <div className="form-group" style={{marginBottom:"25px"}}>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Nome"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                          {/* <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" style={{marginBottom:"25px"}}>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="E-mail"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {/* <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" style={{marginBottom:"25px"}}>
                          <InputMask 
                            mask="(99)99999-9999" 
                            required 
                            className="form-control" 
                            type="text" 
                            id="celular" 
                            name="celular" 
                            placeholder="Celular"
                            onChange={(e) => setCelular(e.target.value)} 
                            value={celular}
                          />
                          {/* <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" style={{marginBottom:"25px"}}>
                          <InputMask 
                            mask="999.999.999-99"  
                            className="form-control" 
                            type="text" 
                            id="cpf" 
                            name="cpf" 
                            placeholder="CPF (Opcional)"
                            onChange={(e) => setCpf(e.target.value)} 
                            value={cpf}
                          />
                          {/* <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" style={{marginBottom:"25px"}}>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="4"
                            placeholder="Mensagem"
                            required
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                          ></textarea>
                          {/* <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                      <div id="success"></div>
                      <button type="submit" className="btn btn-custom btn-lg">
                        Enviar
                      </button>
                      <br/>
                      <div id="contact">
                        <div className="social">
                          <ul>
                            <li>
                              <a target="_blank" rel="noreferrer" href={props.data ? props.data.facebook : "/"}>
                                <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a target="_blank" rel="noreferrer" href={props.data ? props.data.instagram : "/"}>
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a target="_blank" rel="noreferrer" href={props.data ? props.data.whatsapp : "/"}>
                                <i className="fa fa-whatsapp"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};
